




















































































































































































































import { CurveFunction, PowerSpeedCurveConfig, PowerSpeedCurveData } from "@/types/PowerSpeedCurveConfig";
import { Component, Prop, PropSync, Vue, Watch, Ref } from "vue-property-decorator";
import store from "@/store";
import { getModule } from "vuex-module-decorators";
import { curveTypeFormula } from "@/consts/curveConfigDefaults";
import numericFilter from "Utilities/numericFilter";
import _ from "underscore";
//  components
import VesselSelectSimple from "@/components/VesselSelectSimple.vue";
import PowerSpeedCurveDataEditDialog from "@/components/referencecurves/PowerSpeedCurveDataEditDialog.vue";
import ConfirmDialog from "@/components/ConfirmDialog.vue";
// types
import { Vessel } from "@/types/Vessel";
import { Company } from "@/types/company";
//  clients
import PowerSpeedCurveClient from "Clients/power-speed-curve-client";
import VesselsClient from "Clients/vessels-client";
//  modules
import CompaniesModule from "@/store/clients/Companies.module";
import UserModule from "@/store/clients/User.module";

const Companies = getModule(CompaniesModule, store);
const User = getModule(UserModule, store);

@Component({
  components: {
    VesselSelectSimple,
    PowerSpeedCurveDataEditDialog,
    ConfirmDialog,
  },
})
export default class PowerSpeedConfigEditDialog extends Vue {
  @Ref("confirmDelete") confirmDelete!: any;
  @PropSync("value", { required: true }) isOpen!: boolean;
  @PropSync("maxWidth", { default: 1000 }) dialogWidth!: number;
  @Prop({ required: true }) config!: PowerSpeedCurveConfig;
  @Prop({ required: true }) allVessels!: Vessel[];
  // @Prop({ required: true }) currentCompanyVessels!: Vessel[];
  selectedTab = 0;
  clonedConfig: PowerSpeedCurveConfig | null = null;
  selectedVessels: Vessel[] = [];
  selectedCompanyId: number | null = null;
  showAllCompany: Partial<Company> = { name: "Show All Vessels", id: 0 };

  editCurveSegmentDialog = false;
  curveSegmentToEdit: PowerSpeedCurveData | null = null;
  curveSegmentIndexToEdit = -1;

  headers = [
    {
      text: "Curve",
      value: "curveFunction",
      class: "v-data-table-column-header",
      width: "10%",
      sortable: true,
    },
    {
      text: "Curve Formula",
      value: "curveFormula",
      class: "v-data-table-column-header",
      width: "20%",
    },
    {
      text: "Speed Range (knots)",
      value: "speed",
      class: "v-data-table-column-header",
      width: "10%",
    },
    {
      text: "Draft Range (m)",
      value: "draft",
      class: "v-data-table-column-header",
      width: "10%",
    },
    {
      text: "Constants",
      value: "constants",
      class: "v-data-table-column-header",
      width: "10%",
    },

    {
      text: "Edit",
      value: "edit",
      class: "v-data-table-column-header",
      width: "5%",
      sortable: false,
    },
    {
      text: "Delete",
      value: "delete",
      class: "v-data-table-column-header",
      width: "5%",
      sortable: false,
    },
  ];
  searchQuery: string | null = null;
  debounceLoader = false;
  currentCompanyVessels: Vessel[] = [];

  @Watch("config")
  handleConfigChange(): void {
    this.clonedConfig = JSON.parse(JSON.stringify(this.config));
    const alreadySelectedVesselIds = this.clonedConfig?.vesselIds;
    this.selectedVessels = this.allVessels.filter(v => alreadySelectedVesselIds?.some(i => i === v.id));
  }

  get companies(): Company[] {
    return Companies.companies;
  }

  get userCompanyId(): number | null {
    return User.userCompanyId;
  }

  get filteredVessels(): Vessel[] {
    if (this.searchQuery == null) return this.selectedCompanyId === 0 ? this.allVessels : this.currentCompanyVessels;
    const vessels = this.selectedCompanyId === 0 ? this.allVessels : this.currentCompanyVessels;
    return vessels.filter(v => v.name.toLowerCase().includes((this.searchQuery as string).toLowerCase()));
  }

  get isNoData(): boolean {
    return Boolean(this.clonedConfig?.powerSpeedCurveData.length === 0);
  }

  numericFilter(event: any): number {
    return numericFilter(event);
  }

  async deleteCurveSegment(curveData: PowerSpeedCurveData): Promise<void> {
    const confirmed = await this.confirmDelete.open("Delete curve segment", "Are you sure you want to delete this curve segment?");
    if (confirmed) {
      if (!this.clonedConfig) return;
      const index = this.clonedConfig.powerSpeedCurveData.indexOf(curveData);
      if (index > -1) this.clonedConfig.powerSpeedCurveData.splice(index, 1);
    }
  }

  updateSearchQueryInput(newValue: string): void {
    this.debounceLoader = true;
    this.searchQuery = newValue;
    setTimeout(() => (this.debounceLoader = false), 500);
  }

  doSearchDebounced = _.debounce(this.updateSearchQueryInput, 1000);

  addNewCurve(): void {
    const newCurve: PowerSpeedCurveData = {
      id: 0,
      curveFunction: CurveFunction.Hoerl,
      speedMin: 0,
      speedMax: 25,
      draftMin: 0,
      draftMax: 25,
      powerSpeedCurveParameters: [],
    };
    this.clonedConfig?.powerSpeedCurveData.push(newCurve);
    const index = (this.clonedConfig?.powerSpeedCurveData.length ?? 0) - 1;
    this.openEditCurveSegmentModal(newCurve, index);
  }

  curveFormula(curveFunction: CurveFunction): string {
    return curveTypeFormula[curveFunction];
  }

  async saveConfig(): Promise<void> {
    if (this.clonedConfig) {
      this.clonedConfig.vesselIds = this.selectedVessels.map(v => v.id);
      await PowerSpeedCurveClient.updateConfig(this.clonedConfig);
    }
    this.isOpen = false;
    this.$emit("onConfigSave");
  }

  discardChanges(): void {
    this.handleConfigChange();
    this.isOpen = false;
  }

  openEditCurveSegmentModal(curveData: PowerSpeedCurveData, index: number): void {
    this.editCurveSegmentDialog = true;
    this.curveSegmentToEdit = curveData;
    this.curveSegmentIndexToEdit = index;
  }

  async onCompanyChanged(): Promise<void> {
    this.currentCompanyVessels = await VesselsClient.getVesselsByCompanyId(this.selectedCompanyId);
  }

  handleCurveSegmentSave(updatedCurveData: PowerSpeedCurveData, index: number): void {
    if (!this.clonedConfig) return;
    if (index > -1) {
      this.clonedConfig.powerSpeedCurveData.splice(index, 1, updatedCurveData);
      this.config.powerSpeedCurveData.splice(index, 1, updatedCurveData);
    }
  }

  async created(): Promise<void> {
    await Companies.refreshCompanies();
    this.currentCompanyVessels = await VesselsClient.getVessels();
    this.companies.unshift(this.showAllCompany as Company);
    this.selectedCompanyId = this.userCompanyId;
  }
}
