




































































































































import { Component, Vue, Ref } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import store from "@/store";
import _ from "underscore";
// components
import PowerSpeedConfigEditDialog from "@/components/referencecurves/PowerSpeedConfigEditDialog.vue";
import ConfirmDialog from "@/components/ConfirmDialog.vue";
//  clients
import PowerSpeedCurveClient from "Clients/power-speed-curve-client";
import VesselsClient from "Clients/vessels-client";
// types
import { PowerSpeedCurveConfig, PowerSpeedCurveData } from "@/types/PowerSpeedCurveConfig";
import { Vessel } from "@/types/Vessel";
// modules
import UserModule from "@/store/clients/User.module";

const User = getModule(UserModule, store);

@Component({
  components: {
    PowerSpeedConfigEditDialog,
    ConfirmDialog,
  },
})
export default class PowerSpeed extends Vue {
  @Ref("confirmDelete") confirmDelete!: any;
  editDialog = false;
  configToEdit: PowerSpeedCurveConfig | null = null;
  configs: PowerSpeedCurveConfig[] = [];
  allVessels: Vessel[] = [];
  currentCompanyVessels: Vessel[] = [];
  doSearchDebounced = _.debounce(this.updateSearchQueryInput, 1000);
  searchQuery: string | null = null;
  debounceLoader = false;
  headers = [
    {
      text: "Status",
      value: "isValid",
      class: "v-data-table-column-header",
      width: "5%",
      sortable: false,
    },
    {
      text: "Id",
      value: "id",
      class: "v-data-table-column-header",
      width: "5%",
    },
    {
      text: "Description",
      value: "description",
      class: "v-data-table-column-header",
      width: "20%",
    },
    {
      text: "Vessels",
      value: "vessels",
      class: "v-data-table-column-header",
      width: "60%",
    },
    {
      text: "Edit",
      value: "edit",
      class: "v-data-table-column-header",
      width: "5%",
      sortable: false,
    },
    {
      text: "Delete",
      value: "delete",
      class: "v-data-table-column-header",
      width: "5%",
      sortable: false,
    },
  ];

  get isAdmin(): boolean {
    return User.isAdmin;
  }

  get isNoData(): boolean {
    return Boolean(this.configs.length === 0);
  }

  get filtredConfigs(): PowerSpeedCurveConfig[] {
    if (!this.searchQuery) return this.configs;

    //  if searchable item is a vessel < >
    let vesselInSearch: Vessel | null = null;
    const isVesselInSearch = this.allVessels.some(v => {
      if (v.name.toLowerCase().includes((this.searchQuery as string).toLowerCase())) {
        vesselInSearch = v;
        return true;
      }
    });
    if (isVesselInSearch) {
      return this.configs.filter(config => {
        const hasSearchQuery = config.vesselIds.filter(id => {
          if (id === vesselInSearch?.id) {
            return id;
          }
        });
        return hasSearchQuery.length ? config : false;
      });
      //  if searchable item is a vessel </>
    } else {
      return this.configs.filter(c => c.description && c.description.toLowerCase().includes((this.searchQuery as string).toLowerCase()));
    }
  }

  async fetchConfigs(): Promise<void> {
    this.configs = await PowerSpeedCurveClient.getAllConfigs();
  }

  updateSearchQueryInput(newValue: string): void {
    this.debounceLoader = true;
    this.searchQuery = newValue;
    setTimeout(() => (this.debounceLoader = false), 500);
  }

  async addConfig(): Promise<void> {
    await PowerSpeedCurveClient.postEmptyConfig();
    this.configs = await PowerSpeedCurveClient.getAllConfigs();
    this.openConfigEditDialog(this.configs[this.configs.length - 1]);
  }

  async deleteConfig(config: PowerSpeedCurveConfig): Promise<void> {
    const confirmed = await this.confirmDelete.open("Delete config", "Are you sure you want to delete this config?");
    if (confirmed) {
      await PowerSpeedCurveClient.deleteConfig(config.id);
      this.configs = await PowerSpeedCurveClient.getAllConfigs();
    }
  }

  openConfigEditDialog(config: PowerSpeedCurveConfig): void {
    this.editDialog = true;
    this.configToEdit = config;
  }

  getVesselName(vesselId: number): string | undefined {
    return this.allVessels.find(v => v.id === vesselId)?.name;
  }

  isValidCurveData(curveData: PowerSpeedCurveData): boolean {
    let minNumberOfConstants = curveData.curveFunction === "Rational" ? 4 : 3;

    switch (curveData.curveFunction) {
      case "Rational":
        minNumberOfConstants = 4;
        break;
      case "Power":
        minNumberOfConstants = 2;
        break;
      default:
        minNumberOfConstants = 3;
        break;
    }
    const numberOfConstants = curveData.powerSpeedCurveParameters.length;
    return numberOfConstants >= minNumberOfConstants;
  }

  isConfigValid(config: PowerSpeedCurveConfig): boolean {
    const hasAtLeastOneCurve = config.powerSpeedCurveData.length > 0;
    const areCurvesValid = config.powerSpeedCurveData.every(c => this.isValidCurveData(c));
    return hasAtLeastOneCurve && areCurvesValid;
  }

  async created(): Promise<void> {
    if (!this.isAdmin) this.$router.push("/");
    this.fetchConfigs();
    const ignoreCompany = true;
    this.allVessels = await VesselsClient.getVessels(ignoreCompany);
  }
}
