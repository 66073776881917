






























import { Component, Vue, PropSync, Prop } from "vue-property-decorator";
import { Vessel } from "@/types/Vessel";

@Component({})
export default class VesselSelectSimple extends Vue {
  @PropSync("selectedVesselsList", { type: Array, default: () => [] })
  selectedVessels!: Vessel[];
  @Prop({ type: Array, default: () => [], required: true })
  vessels!: Vessel[];

  get selectedVesselIds(): Record<number, boolean> {
    const vesselIds: Record<number, boolean> = {};
    this.vessels.forEach(vessel => {
      vesselIds[vessel.id] = this.isSelected(vessel.id);
    });
    return vesselIds;
  }

  set selectedVesselIds(value: Record<number, boolean>) {
    Object.keys(value).forEach(vesselId => {
      const id = parseInt(vesselId, 10);
      const vessel = this.vessels.find(v => v.id === id);
      if (vessel && value[id] !== this.isSelected(id)) {
        this.toggleVesselSelection(vessel);
      }
    });
  }

  toggleVesselSelection(vessel: Vessel): void {
    const index = this.selectedVessels.findIndex(v => v.id === vessel.id);
    if (index === -1) {
      this.selectedVessels.push(vessel);
    } else {
      this.selectedVessels.splice(index, 1);
    }
    this.$emit("onSelectionChange", vessel);
  }

  isSelected(id: number): boolean {
    return this.selectedVessels.some(v => v.id === id);
  }
}
