import { CurveFunction } from "@/types/PowerSpeedCurveConfig";

type CurveConstantCount = {
  // eslint-disable-next-line no-unused-vars
  [key in CurveFunction]: {
    min: number;
    max: number;
  };
};

type CurveFunctionFormula = {
  // eslint-disable-next-line no-unused-vars
  [key in CurveFunction]: string;
};

export const curveConstantsCount: CurveConstantCount = {
  [CurveFunction.Hoerl]: {
    min: 3,
    max: 3,
  },
  [CurveFunction.Rational]: {
    min: 4,
    max: 4,
  },
  [CurveFunction.Polynomial]: {
    min: 3,
    max: 7,
  },
  [CurveFunction.Power]: {
    min: 3,
    max: 7,
  },
};

export const curveTypeFormula: CurveFunctionFormula = {
  [CurveFunction.Hoerl]: "y=A*(B^x)*(x^C)",
  [CurveFunction.Rational]: "y=(A+Bx)/(1+Cx+Dx^2)",
  [CurveFunction.Polynomial]: "y=A+Bx+Cx^2+Dx^3...",
  [CurveFunction.Power]: "y=A*x^B",
};
