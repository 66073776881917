import net from "App/net";

class PowerSpeedCurveClient {
  constructor() {
  }

  getAllConfigs() {
    return net.get("/ReferenceCurves/PowerSpeed/Configs");
  }

  postEmptyConfig() {
    return net.post("/ReferenceCurves/PowerSpeed/Configs");
  }

  updateConfig(config) {
    return net.put("/ReferenceCurves/PowerSpeed/Configs", config);
  }

  deleteConfig(id) {
    return net.del(`/ReferenceCurves/PowerSpeed/Configs/${id}`);
  }
}


export default new PowerSpeedCurveClient();
