export interface PowerSpeedCurveConfig {
  id: number;
  description: string;
  powerSpeedCurveData: PowerSpeedCurveData[];
  vesselIds: number[];
}

export interface PowerSpeedCurveData {
  id: number;
  curveFunction: CurveFunction;
  speedMin: number;
  speedMax: number;
  draftMin: number;
  draftMax: number;
  powerSpeedCurveParameters: PowerSpeedCurveParameter[];
}

export interface PowerSpeedCurveParameter {
  id: number;
  constant: string;
  a0: number;
  a1: number;
  a2: number;
}

export enum CurveFunction {
  Hoerl = "Hoerl",
  Polynomial = "Polynomial",
  Rational = "Rational",
  Power = "Power",
}
